<template>
 <div ref="markdownContent" :v-html="rendered">
    <slot></slot>
 </div>
 
</template>

<script>
import MarkdownIt from 'markdown-it';     
// see https://markdown-it.github.io/markdown-it/ for underlying engine
// see https://github.com/ravenq/markdown-it-vue/blob/master/src/markdown-it-vue.vue for full example
export default {
  name: "VueMarkdown",
  components: {
  },
  props: {
    html: { type: Boolean, default: false },
    source: { type: String, default: null },
  },
  data: function() {
    return {
        md: null,
        rendered: null,
    };
  },
  async mounted() {
      this.md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true
      });
      var markdown = this.$refs.markdownContent.innerHTML;
      if (markdown) {
        this.render(markdown);
      }
      else if (this.source) {
        this.render(this.source);
      }
  },
  methods: {
      render(markdown) {
        if (!markdown) return;
        var rendered = this.md.render(markdown);
        //console.log(markdown, '-->', rendered);
        this.$refs.markdownContent.innerHTML = rendered;
      },
  },
  watch: {
      async source(val) {
        await this.$nextTick();
        this.render(val);
      }
  }
};
</script>

<style lang="scss">
</style>